<template>
  <div>
    <b-overlay
      :show="show"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  sm="5"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Diurno / Nocturno"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Diurno / Nocturno
                      </h6>
                      <v-select
                        v-model="conservacion.tipoFormulario"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        :options="jornada"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="7"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Categoría"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Categoría
                      </h6>
                      <v-select
                        v-model="conservacion.categoria"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        :options="categoriasConservacion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Zona"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Zona
                      </h6>
                      <v-select
                        v-model="conservacion.zona"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        :options="zonas"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Grupo"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Grupo
                      </h6>
                      <b-form-input
                        v-model="conservacion.grupo"
                        :state="errors.length > 0 ? false:null"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Identificación / Poste"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Identificación / Poste
                      </h6>
                      <b-form-input
                        v-model="conservacion.posteReferencia"
                        :state="errors.length > 0 ? false:null"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <h6 class="text-primary">
                    Fotografía Panorámica del Antes
                  </h6>
                  <imagen
                    :key="'foto-panoramica-antes'"
                    :leyenda="'foto-panoramica-antes'"
                    @cargar-file="fotografiaPanoramicaAntes"
                  />
                </b-col>
              </b-row>
              <h2 class="text-primary">
                Trabajo Realizado
              </h2>
              <hr>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Trabajo Realizado"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Selecciona el Trabajo Realizado
                      </h6>
                      <v-select
                        v-model="trabajosRealizados"
                        :state="errors.length > 0 ? false:null"
                        label="nombre"
                        :options="trabajosConservacion"
                        multiple
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="trabajosRealizados.filter(i => i.id === 2).length > 0">
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <h6 class="text-primary">
                      Código Anterior
                    </h6>
                    <b-form-input
                      v-model="cambioLampara.codigoAnterior"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <h6 class="text-primary">
                      Código Nuevo
                    </h6>
                    <b-form-input
                      v-model="cambioLampara.codigoNuevo"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <h6 class="text-primary">
                      Potencia
                    </h6>
                    <b-form-input
                      v-model="cambioLampara.potencia"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="trabajosRealizados.filter(i => i.id === 3).length > 0">
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <b-form-group>
                      <h6 class="text-primary">
                        Brazo Retirado
                      </h6>
                      <v-select
                        v-model="cambioBrazo.brazoRetirado"
                        label="nombre"
                        :options="medidaBrazo"
                      />
                    </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <h6 class="text-primary">
                      Brazo Instalado
                    </h6>
                    <v-select
                      v-model="cambioBrazo.brazoInstalado"
                      label="nombre"
                      :options="medidaBrazo"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="trabajosRealizados.filter(i => i.id === 8).length > 0">
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group>
                    <h6 class="text-primary">
                      Metros de Poda
                    </h6>
                    <b-form-input
                      v-model="metrosPoda"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group>
                    <h6 class="text-primary">
                      Fotografía Panorámica del Después
                    </h6>
                    <imagen
                      :key="'foto-panoramica-despues'"
                      :leyenda="'foto-panoramica-despues'"
                      @cargar-file="fotografiaPanoramicaDespues"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Observaciones"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Observaciones
                      </h6>
                      <b-form-textarea
                        v-model="conservacion.observaciones"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                        no-resize
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <!-- BOTONES -->
                <b-col
                  class="d-flex justify-content-end"
                  cols="12"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Guardar
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BOverlay,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getListadoConservacion, updateCreateConservacion } from '@/utils/mantenimiento/conservacion/conservacionUtils'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { saveImagen } from '@/utils/imagenes'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getlistado } from '@/utils/catalogos'
import { getPoste } from '@/utils/postes'
import { getListadoZonas } from '@/utils/localizacion'

export default {
  components: {
    Imagen,
    BOverlay,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    isNew: {
      type: Boolean,
      default: false,
      required: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      conservacion: {
        fechaCreacion: new Date(),
        tipoFormulario: null,
        categoria: null,
        zona: '',
        grupo: '',
        posteReferencia: '',
        observaciones: '',
        seguimiento: [],
        tracking: [
          {
            estado: 'INGRESADA',
            horaFecha: new Date(),
          },
        ],
        estado: 'INGRESADA',
        trabajosRealizados: [],
        usuario: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      posteId: router.currentRoute.params.poste,
      poste: null,
      trabajosRealizados: [],
      localization: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
      fotoPanoramicaAntes: '',
      fotoPanoramicaDespues: '',
      zonas: [],
      jornada: [],
      medidaBrazo: [],
      trabajosConservacion: [],
      categoriasConservacion: [],
      required,
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
      cambioLampara: {
        codigoAnterior: '',
        codigoNuevo: '',
        potencia: '',
      },
      cambioBrazo: {
        brazoRetirado: '',
        brazoInstalado: '',
      },
      metrosPoda: '',
    }
  },
  async created() {
    this.poste = await getPoste(this.posteId)
    this.conservacion.posteReferencia = this.poste.numeroPoste
    this.conservacion.grupo = this.poste.objGrupo.nombre
    const items = await getlistado(16, 'Trabajos Conservación', false, this.usuario)
    this.trabajosConservacion = items.filter(item => item.tipo === 'TRABAJO')
    this.categoriasConservacion = items.filter(item => item.tipo !== 'TRABAJO')

    this.medidaBrazo = await getlistado(14, 'Tipos de Brazo', false, this.usuario)
    this.zonas = getListadoZonas(1)
    this.jornada = getListadoConservacion(1)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    changeEditable() {
      this.editable = false
    },
    async cancel() {
      this.editable = true
    },
    async guardarConservacion() {
      console.log(this.conservacion)
      if (!this.fotoPanoramicaAntes || this.fotoPanoramicaAntes === '') {
        mensajeError('Debe Ingresar una Fotografía Panorámica del Antes!')
        return
      }
      if (!this.fotoPanoramicaDespues || this.fotoPanoramicaDespues === '') {
        mensajeError('Debe Ingresar una Fotografía Panorámica del Después!')
        return
      }
      this.show = true

      if (this.localization === null || this.localization === undefined) {
        mensajeError('Error obteniendo geolocalización, intente nuevamente!')
        return
      }

      this.conservacion.usuario = {
        id: this.usuario.id,
        nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        email: this.usuario.email,
        telefono: '',
      }

      const body = { ...this.conservacion }
      body.grupo = this.conservacion.grupo
      body.zona = this.conservacion.zona.value

      this.trabajosRealizados.forEach(job => {
        const trabajo = { ...job }
        if (trabajo.id === 2) trabajo.trabajo = this.cambioLampara
        if (trabajo.id === 3) trabajo.trabajo = this.cambioBrazo
        if (trabajo.id === 8) trabajo.trabajo = this.metrosPoda
        body.trabajosRealizados.push(trabajo)
      })
      const item = await updateCreateConservacion(body, 2)
      if (!item) {
        mensajeError('Inconvenientes almacenando la conservación!, intente nuevamente!')
        this.show = false
        return
      }

      const urlBucket = `conservaciones/${item.id}`
      const isMobile = window.screen.width <= 760

      const imagen1 = await saveImagen(urlBucket, this.localization, this.fotoPanoramicaAntes, 'Foto Panorámica Antes', '', this.conservacion.usuario, isMobile)
      if (imagen1) item.seguimiento.push(imagen1)
      const imagen2 = await saveImagen(urlBucket, this.localization, this.fotoPanoramicaDespues, 'Foto Panorámica Después', '', this.conservacion.usuario, isMobile)
      if (imagen2) item.seguimiento.push(imagen2)
      await updateCreateConservacion(item, 1)

      mensajeInformativo('Guardada', 'Conservación almacenada perfectamente!')
      this.$router.replace('/conservacion').then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Conservación Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Conservación ingresada correctamente!',
          },
        })
      })
      this.show = false
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.guardarConservacion()
        }
      })
    },
    fotografiaPanoramicaAntes(file) {
      this.fotoPanoramicaAntes = file
    },
    fotografiaPanoramicaDespues(file) {
      this.fotoPanoramicaDespues = file
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
